import React from 'react'
import { Link } from 'gatsby'

class Navi extends React.Component {
  constructor(props) {
    super(props)
    this.toggleNavbar = this.toggleNavbar.bind(this)
    this.state = {
      collapsed: true,
    }
  }
  toggleNavbar() {
    this.setState({
      collapsed: !this.state.collapsed,
    })
  }
  openModal() {
    document.body.classList.toggle('search-form-on')
  }
  render() {
    const collapsed = this.state.collapsed
    const classOne = collapsed
      ? 'collapse navbar-collapse'
      : 'collapse navbar-collapse show'
    const classTwo = collapsed
      ? 'navbar-toggler navbar-toggler-right collapsed'
      : 'navbar-toggler navbar-toggler-right'
    const { title } = this.props
    return (
      <div className="bottom-header">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12">
              <div className="main-menu">
                <nav className="navbar navbar-expand-lg">
                  <Link className="nav-link sitetitle" to="/">
                    Foudelom.fr
                  </Link>
                  <button
                    onClick={this.toggleNavbar}
                    className={`${classTwo}`}
                    type="button"
                    data-toggle="collapse"
                    data-target="#gazetteMenu"
                    aria-controls="gazetteMenu"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <i className="fa fa-bars" /> Menu
                  </button>
                  <div className={`${classOne}`} id="gazetteMenu">
                    <ul className="navbar-nav mr-auto">
                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          to="/1/"
                        >
                          Actualité
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          to="/calendrier-resultats-om-ligue-1-2019-2020/"
                        >
                          Calendrier / Résultats
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          to="/classement-ligue-1-2019-2020/"
                        >
                          Classement
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link" to="/contact/">
                          Contact
                        </Link>
                      </li>
                    </ul>
                    <div className="header-search-form mr-auto">
                      <form action="#">
                        <input
                          type="search"
                          placeholder="Input your keyword then press enter..."
                          id="search"
                          name="search"
                        />
                        <input
                          className="d-none"
                          type="submit"
                          value="submit"
                        />
                      </form>
                    </div>
                    <div id="searchbtn" onClick={() => this.openModal()}>
                      <i className="fa fa-search" aria-hidden="true" />
                    </div>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Navi
