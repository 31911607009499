import React from 'react'
import { Link } from 'gatsby'

import { getPostPath } from '../utils/helpers'

const Card = post => {
  const { title, date, excerpt, thumbnail } = post
  const isThumbnail = typeof thumbnail !== 'undefined'
  return (
    <div className="col-12 col-md-4">
      <Link to={getPostPath(post)}>
        <div className="gazette-welcome-post">
          <h2 className="font-pt" dangerouslySetInnerHTML={{ __html: title }} />
          <p className="gazette-post-date">{date}</p>
          {isThumbnail ? (
            <div className="blog-post-thumbnail my-5">
              <img src={thumbnail} alt="post-thumb" />
            </div>
          ) : (
            <div />
          )}
          <p
            dangerouslySetInnerHTML={{
              __html: excerpt,
            }}
          />
        </div>
      </Link>
    </div>
  )
}

export default Card
