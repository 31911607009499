import { graphql } from 'gatsby'
import React from 'react'
import Helmet from 'react-helmet'

import SEO from '../components/SEO'
import Layout from '../components/Layout'
import Breadcrumb from '../components/Breadcrumb'
import CardList from '../components/CardList'
import Card from '../components/Card'
import Pagination from '../components/Pagination'

const Index = ({ data, pageContext }) => {
  if (data.errors) {
    data.errors.forEach(e => console.error(e.toString()))
    return Promise.reject(data.errors)
  }
  const posts = data.allMarkdownRemark.edges
  const { currentPage } = pageContext

  return (
    <Layout>
      <SEO />
      <Helmet>
        <title>{`Actualités de l'Olympique de Marseille - Page ${currentPage}`}</title>
      </Helmet>
      <Breadcrumb
        category={`Actualités de l'Olympique de Marseille - Page ${currentPage}`}
      />
      <CardList>
        <div className="row">
          {posts.map(({ node: post }) => (
            <Card key={post.id} {...post} />
          ))}
          <div className="col-12" />
        </div>
        <Pagination context={pageContext} />
      </CardList>
    </Layout>
  )
}

export const query = graphql`
  query($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { ne: null } }
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 80)
          frontmatter {
            title
            type
            url
            date
            slug
            categories
          }
        }
      }
    }
  }
`

export default Index
