import React from 'react'
import { StaticQuery, Link, graphql } from 'gatsby'
import moment from 'moment'
import 'moment/locale/fr'
import { getCalendarLink, getStandingLink } from '../../utils/helpers'

const TopHeader = () => (
  <StaticQuery
    query={graphql`
      query {
        dataLastMatch: allFootMatch(
          filter: { statusShort: { in: ["FT", "PEN"] } }
          limit: 1
          sort: { fields: event_timestamp, order: DESC }
        ) {
          edges {
            node {
              awayTeam {
                team_name
              }
              event_date
              homeTeam {
                team_name
              }
              score {
                fulltime
              }
              statusShort
              competition {
                season_end
                season_start
                name
              }
            }
          }
        }
        dataNextMatch: allFootMatch(
          filter: { statusShort: { in: ["NS", "TBD"] } }
          limit: 1
          sort: { fields: event_timestamp, order: ASC }
        ) {
          edges {
            node {
              awayTeam {
                team_name
              }
              event_date
              homeTeam {
                team_name
              }
              score {
                fulltime
              }
              statusShort
              competition {
                season_end
                season_start
                name
              }
            }
          }
        }
        footStanding(competition: { league_id: { eq: 525 } }) {
          competition {
            name
            season
            season_end
            season_start
          }
          standing {
            points
            rank
            teamName
            team_id
            logo
            all {
              matchsPlayed
              win
              lose
              goalsFor
              goalsAgainst
              draw
            }
            goalsDiff
          }
        }
      }
    `}
    render={data => {
      let isStanding = false
      let waiting = ''

      const { dataLastMatch, dataNextMatch, footStanding } = data

      const startDate = new Date(footStanding.competition.season_start)
      // const endDate = new Date(footStanding.competition.season_end)
      const today = new Date()
      let OMstanding = {}
      let sup = ''
      if (startDate < today) {
        isStanding = true
        const { standing } = footStanding
        OMstanding = standing.find(obj => obj.team_id === 81)
        sup = OMstanding.rank === 1 ? 'er' : 'ème'
        footStanding.matchLink = getStandingLink(footStanding.competition)
      } else {
        const mStartDate = moment(footStanding.competition.startDate)
        waiting = 'La Ligue 1 reprend le ' + mStartDate.format('LLLL')
      }

      let lastMatch = null
      let nextMatch = null

      if (dataLastMatch.edges.length > 0) {
        lastMatch = dataLastMatch.edges[0].node
        lastMatch.matchLink = getCalendarLink(lastMatch.competition)
      }

      if (dataNextMatch.edges.length > 0) {
        nextMatch = dataNextMatch.edges[0].node
        nextMatch.matchLink = getCalendarLink(nextMatch.competition)
      }

      return (
        <div className="top-header">
          <div className="container h-100">
            <div className="row h-100 align-items-center">
              <div className="col-12 col-md-4">
                <div className="finished text-center">
                  {lastMatch && (
                    <Link to={lastMatch.matchLink}>
                      <span className="home">{lastMatch.homeTeam.team_name}</span>
                      <span className="scoreHome">
                        {lastMatch.score.fulltime.replace('-', ' - ')}
                      </span>
                      <span className="away">{lastMatch.awayTeam.team_name}</span>
                    </Link>
                  )}
                </div>
              </div>
              <div className="col-12 col-md-4">
                {isStanding && (
                  <div className="standing text-center">
                    <Link to={footStanding.matchLink}>
                      <span className="championship">Ligue 1</span>
                      <span className="position">
                        {OMstanding.rank}
                        <sup className="sup">{sup}</sup>
                      </span>
                      <span className="details">
                        <span className="points">
                          <span className="label">PTS</span>
                          {OMstanding.points}
                        </span>
                        <span className="played">
                          <span className="label">J</span>
                          {OMstanding.all.matchsPlayed}
                        </span>
                        <span className="won">
                          <span className="label">G</span>
                          {OMstanding.all.win}
                        </span>
                        <span className="draw">
                          <span className="label">N</span>
                          {OMstanding.all.draw}
                        </span>
                        <span className="lost">
                          <span className="label">P</span>
                          {OMstanding.all.lose}
                        </span>
                      </span>
                    </Link>
                  </div>
                )}
                {!isStanding && (
                  <div className="standing text-center">{waiting}</div>
                )}
              </div>
              <div className="col-12 col-md-4">
                <div className="scheduled text-center">
                  {nextMatch && (
                    <Link to={nextMatch.matchLink}>
                      <span className="teams">
                        {nextMatch.homeTeam.team_name} -{' '}
                        {nextMatch.awayTeam.team_name}
                      </span>
                      <span className="date">
                        {moment(nextMatch.event_date).format('llll')}
                      </span>
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }}
  />
)
export default TopHeader
