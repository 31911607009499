import React from 'react'
import { Link } from 'gatsby'

const Breadcrumb = ({ category }) => {
  return (
    <div className="breadcumb-area section_padding_50">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="breacumb-content d-flex align-items-center justify-content-between">
              <div className="gazette-post-tag">{category}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Breadcrumb
