import React from 'react'
// import 'bootstrap/dist/js/bootstrap.js'

import TopHeader from './Common/TopHeader'
import Navi from './Navi'
import Footer from './Footer'
import { siteMetadata } from '../../gatsby-config'

import 'bootstrap/dist/css/bootstrap.css'
import 'font-awesome/css/font-awesome.css'
import './core-style.css'
import './responsive.css'
import './style.css'

class Layout extends React.Component {
  render() {
    const { children } = this.props
    return (
      <div>
        <header className="header-area">
          <TopHeader />
          <Navi title={siteMetadata.title} {...this.props} />
        </header>
        {children}
        <Footer title={siteMetadata.title} author={siteMetadata.author} />
      </div>
    )
  }
}

export default Layout
