import { Link } from 'gatsby'
import React from 'react'

const Footer = ({ author, title }) => (
  <footer className="footer-area bg-blue">
    <div className="top-footer-area section_padding_100_70">
      <div className="container">
        <div className="row" />
      </div>
    </div>

    <div className="bottom-footer-area">
      <div className="container h-100">
        <div className="row h-100 align-items-center justify-content-center">
          <div className="col-12 col-md-6"></div>
          <div className="col-12 col-md-6">
            <div className="copywrite-text">
              <p>
                Copyright &copy;
                <script>document.write(new Date().getFullYear());</script> All
                rights reserved | This template is made with{' '}
                <i className="fa fa-heart-o" aria-hidden="true" /> by{' '}
                <a
                  href="https://colorlib.com"
                  rel="nofollow noopener noreferrer"
                  target="_blank"
                >
                  Colorlib
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
)

export default Footer
