import React from 'react'

const CardList = props => {
  return (
    <section className="catagory-welcome-post-area section_padding_100">
      <div className="container">{props.children}</div>
    </section>
  )
}

export default CardList
