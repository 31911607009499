import React from 'react'
import { Link } from 'gatsby'

class Pagination extends React.Component {
  render() {
    const { numPages, currentPage } = this.props.context
    const isFirst = currentPage === 1
    const isLast = currentPage === numPages
    const isNotPaginated = isFirst & isLast

    const prevPageNum = currentPage - 1 === 1 ? `` : currentPage - 1
    const nextPageNum = currentPage + 1

    const prevPageLink = isFirst ? null : `${prevPageNum}/`
    const nextPageLink = isLast ? null : `${nextPageNum}/`

    return (
      <div className="row">
        <div className="col-12">
          <div className="gazette-pagination-area">
            <nav aria-label="Page navigation example">
              <ul className="pagination">
                {!isFirst && (
                  <li className="page-item">
                    <Link className="page-link" to={prevPageLink}>
                      <i className="fa fa-angle-left" />
                    </Link>
                  </li>
                )}
                {!isLast && (
                  <li className="page-item">
                    <Link className="page-link" to={nextPageLink}>
                      <i className="fa fa-angle-right" />
                    </Link>
                  </li>
                )}
              </ul>
            </nav>
          </div>
        </div>
      </div>
    )
  }
}

export default Pagination
