const getCompetitionLink = competition => {
  String.prototype.replaceAll = function(search, replacement) {
    const target = this
    return target.replace(new RegExp(search, 'g'), replacement)
  }

  const startDate = new Date(competition.season_start)
  const endDate = new Date(competition.season_end)
  return `${competition.name
    .toLowerCase()
    .replaceAll(' ', '-')}-${startDate.getFullYear()}-${endDate.getFullYear()}/`
}

const getCalendarLink = competition => {
  return `calendrier-resultats-om-${getCompetitionLink(competition)}`
}

const getStandingLink = competition => {
  return `classement-${getCompetitionLink(competition)}`
}

const getPostPath = post => {
  let path = ''

  if (post.frontmatter.type === 'post') {
    // archives
    path = post.frontmatter.url
  } else {
    // netlify cms
    const date = new Date(post.frontmatter.date)
    const month = ('0' + (date.getMonth() + 1)).slice(-2)
    const day = ('0' + date.getDate()).slice(-2)
    path = `/${date.getFullYear()}/${month}/${day}/${post.frontmatter.slug}/`
  }
  return path
}

module.exports = {
  getCalendarLink,
  getCompetitionLink,
  getPostPath,
  getStandingLink,
}
