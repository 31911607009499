module.exports = {
  siteTitle: 'Foudelom',
  siteTitleAlt: "LE site d'un Fou de l'OM", // This allows an alternative site title for SEO schema.
  publisher: 'Foudelom', // Organization name used for SEO schema
  siteDescription: "Actu de l'Olympique de Marseille",
  siteUrl: 'https://www.foudelom.fr', // Site domain. Do not include a trailing slash! If you wish to use a path prefix you can read more about that here: https://www.gatsbyjs.org/docs/path-prefix/
  postsPerHomePage: 9, // Number of posts shown on the 1st page of of the index.js template (home page)
  postsPerPage: 9, // Number of posts shown on paginated pages
  author: 'Ludo', // Author for RSS author segment and SEO schema
  authorUrl: 'https://www.foudelom.fr/about/', // URL used for author and publisher schema, can be a social profile or other personal site
  userTwitter: '@foudelom', // Change for Twitter Cards
  shortTitle: 'Foudelom.fr', // Used for App manifest e.g. Mobile Home Screen
  shareImage: '/logos/share.jpg', // Open Graph Default Share Image. 1200x1200 is recommended
  shareImageWidth: 900, // Change to the width of your default share image
  shareImageHeight: 600, // Change to the height of your default share image
  siteLogo: '/logos/logo-512.png', // Logo used for SEO, RSS, and App manifest
  backgroundColor: '#0084FF', // Used for Offline Manifest
  themeColor: '#FFF', // Used for Offline Manifest
  copyright: 'Copyright © 2019 Foudelom.fr', // Copyright string for the RSS feed
  youtube: {
    searches: [
      { channelId: 'UCoKweTwEeA-D9vuSVw_Z_DQ' },
      { channelId: 'UCSzx_YuvN_OpWRCjP83V4-Q' },
      { channelId: 'UCocikWsRAudLmjf61hWhyAw' },
      { channelId: 'UC8AS0FcffkkZzh3t964yi2w' },
      // {
      //   channelId: 'UCQsH5XtIc9hONE1BQjucM0g',
      //   q: 'Olympique de Marseille',
      // }, // LFP
    ],
  },
}
